import { Alert, Grid, IconButton, Typography, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomIcon from "components/CustomIcon";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createNewRecipe, getRecipeLists } from "store/actions/recipes";
import { THUMBNAIL_PLACEHOLDER_GRAY } from "utils/constants";

const StyledAlert = styled(Alert)(() => ({
  cursor: "pointer",
  border: "1px solid #E0E4EA",
  marginBottom: "8px",
  "&:last-child": {
    marginBottom: "0px",
  },
  ".MuiAlert-icon": {
    display: "none",
  },
  ".MuiAlert-action": {
    alignItems: "center",
  },
  ".MuiAlert-message": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "#4F5668",
    fontSize: "14px",
    padding: 0,
  },
}));

function checkAndAddCopy(str) {
  if (str.includes("copy")) {
    const regex = /copy\s*(\d*)$/;
    const match = str.match(regex);
    let count = 1;
    if (match && match[1]) {
      count = parseInt(match[1], 10);
    }
    str = str.replace(regex, "");

    return str.trim() + " copy " + (count + 1);
  } else {
    return str.trim() + " copy";
  }
}

const Status = styled(Typography)(({ theme }) => ({
  background: theme.palette.gradients.primary,
  fontSize: "12px",
  padding: "4px 12px",
  color: "#FFFFFF",
  borderRadius: "44px",
}));

const useStyle = makeStyles(() => ({
  img: {
    height: "40px",
    width: "40px",
    borderRadius: "8px",
  },
}));

const ExcerciceItem = ({ toggleDeleteModel, toggleInfoModel, recipe }) => {
  const dispatch = useDispatch();
  const naviagate = useNavigate();
  const userDetails = useSelector((state) => state.user.userDetails);
  const classes = useStyle();
  return (
    <StyledAlert
      variant="outlined"
      severity="info"
      onClick={() => naviagate(`/nutrition/recipes/${recipe["_id"]}`)}
    >
      <Grid container>
        <Grid
          item
          sm={10}
          sx={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          <img
            src={recipe.thumbnail}
            alt="profile"
            className={classes.img}
            onError={(event) => {
              event.target.src = THUMBNAIL_PLACEHOLDER_GRAY;
              event.onerror = null;
            }}
          />
          {recipe.name}
        </Grid>

        <Grid item sm={2} sx={{ display: "flex", justifyContent: "right" }}>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => naviagate("/fitness/programs/1")}
          >
            <CustomIcon
              iconSrc="/assets/logo/detail_icon.svg"
              iconHoverSrc="/assets/logo/detail_icon_hover.svg"
            />
          </IconButton>
          {process.env.REACT_APP_EVOCOACH_USER_ID === userDetails.user_id ||
            (recipe.created_by !== process.env.REACT_APP_EVOCOACH_USER_ID && (
              <>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    recipe.name = checkAndAddCopy(recipe.name);
                    dispatch(createNewRecipe(recipe)).then(() =>
                      dispatch(getRecipeLists())
                    );
                  }}
                >
                  <CustomIcon
                    iconSrc="/assets/logo/copy.svg"
                    iconHoverSrc="/assets/logo/copy_hover.svg"
                  />
                </IconButton>
                <IconButton
                  color="info.main"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDeleteModel(recipe);
                  }}
                >
                  <CustomIcon
                    iconSrc="/assets/logo/trash_icon.svg"
                    iconHoverSrc="/assets/logo/trash_icon_hover.svg"
                  />
                </IconButton>
              </>
            ))}
        </Grid>
      </Grid>
    </StyledAlert>
  );
};

export default ExcerciceItem;
