import axios from "axios";
import history from "components/history";
import dayjs from "dayjs";
import _ from "lodash";
import { toast } from "react-hot-toast";
import {
  createArrayOfElements,
  createOrOverrideElement,
  replaceArrayObjValues,
} from "utils/array.utils";
import { filterRecords } from "utils/common";
import { THUMBNAIL_PLACEHOLDER } from "utils/constants";
import { muscle } from "utils/gym.utils";
import { programs } from "utils/programs.utils";

export const uploadFile = (file = {}, apiKey, user_id) => {
  return (dispatch) => {
    // if ((file.size / 1024) < 5120) {
    const formData = new FormData();
    formData.append("file", file);
    if (user_id) {
      formData.append("user_id", user_id);
    }
    dispatch(toggleFileUploading(true));
    return axios
      .post(apiKey, formData)
      .then((response) => {
        dispatch(toggleFileUploading(false));
        toast.success("File Uploaded Successfully.");
        return response.data.data.url;
      })
      .catch(() => {
        dispatch(toggleFileUploading(false));
        toast.error("Error While Uploading File");
      });
  };
};

export const SET_ACTIVE_DAY = "set_active_day";
export const setActiveDay = (response = 0) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ACTIVE_DAY,
      payload: response,
    });
  };
};

export const SET_ACTIVE_WEEK = "set_active_week";
export const setActiveWeek = (response) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ACTIVE_WEEK,
      payload: response,
    });
  };
};

export const SET_TOTAL_WEEK = "set_total_week";
export const setTotalWeek = (response) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_TOTAL_WEEK,
      payload: response,
    });
  };
};

export const PAGINATION_PROGRAM_DATA = "pagination_program_data";
export const changePagination = (response = 1) => {
  return (dispatch, getState) => {
    dispatch({
      type: PAGINATION_PROGRAM_DATA,
      payload: response,
    });
  };
};

export const duplicateThisWeek = (formikRef, active = 1) => {
  return (dispatch, getState) => {
    const {
      values: { week },
      setFieldValue,
    } = formikRef.current;
    const totalWeek = getState().programs.totalWeek;
    setFieldValue(`week.${totalWeek}`, [...week[active]]);
    dispatch(setActiveWeek(totalWeek));
    dispatch({ type: SET_TOTAL_WEEK });
  };
};
export const addWeek = (formikRef) => {
  return (dispatch, getState) => {
    const { setFieldValue } = formikRef.current;
    const totalWeek = getState().programs.totalWeek;
    const initialWeekValues = getState().programs.initialProgramValue.week["0"];
    setFieldValue(`week.${totalWeek}`, initialWeekValues);
    dispatch(setActiveWeek(totalWeek));
    dispatch({ type: SET_TOTAL_WEEK });
  };
};

export const removeWeek = (formikRef, active = 1) => {
  return (dispatch, getState) => {
    const {
      values: { week, weeks },
      setFieldValue,
    } = formikRef.current;
    const totalWeek = getState().programs.totalWeek;
    const programItem = getState().programs.programItem;
    if (Object.keys(week).length > 1) {
      delete programItem.weeks[`${active + 1}`];
      delete week[`${active}`];
      if (weeks) delete weeks[`{${active + 1}}`];
      Object.keys(week).forEach((item) => {
        if (item > active) {
          week[item - 1] = week[item];
          delete week[item];
        }
      });
      Object.keys(programItem.weeks).forEach((item) => {
        if (item > active + 1) {
          programItem.weeks[item - 1] = programItem.weeks[item];
          delete programItem.weeks[item];
        }
      });
      setFieldValue(`week`, week);
      setFieldValue(`weeks`, weeks);
      dispatch(setActiveWeek(active - 1 < 0 ? 0 : active - 1));
      dispatch({ type: SET_TOTAL_WEEK, payload: totalWeek - 1 });
      dispatch({ type: SET_CURRENT_PROGRAM, payload: programItem });
    }
  };
};

export const TOGGLE_EXERCISE_MODEL = "toggle_exercise_model";
export const toggleExerciseModel = (response = null) => {
  return {
    type: TOGGLE_EXERCISE_MODEL,
    payload: response,
  };
};

export const TOGGLE_SWAP_EXERCISE_MODEL = "toggle_swap_exercise_model";
export const toggleSwapExerciseModel = () => {
  return {
    type: TOGGLE_SWAP_EXERCISE_MODEL,
  };
};

export const SET_CURRENT_PROGRAM = "set_current_program";
export const setCurrentProgram = (values = { weeks: { 1: {} } }) => {
  return {
    type: SET_CURRENT_PROGRAM,
    payload: values,
  };
};

export const SET_LOADING_PROGRAMS = "set_loading_programs";
export const setLoadingProgramms = (response = false) => {
  return {
    type: SET_LOADING_PROGRAMS,
    payload: response,
  };
};

export const SEARCH_PROGRAMS_DATA = "search_programs_data";
export const searchPrograms = (search = "", filters = []) => {
  return (dispatch, getState) => {
    let filteredItem = getState().programs.searchedProgramms || [];

    filteredItem = filterRecords(filteredItem, search, filters, "status");

    dispatch({
      type: SEARCH_PROGRAMS_DATA,
      payload: filteredItem,
    });
  };
};

export const GET_PROGRAM_LISTS = "get_program_lists";
export const getProgramLists = () => {
  return (dispatch) => {
    dispatch(setLoadingProgramms(true));
    return axios
      .get("/api/coach/programs")
      .then((res) => {
        dispatch(setLoadingProgramms(false));
        dispatch({
          type: GET_PROGRAM_LISTS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch(setLoadingProgramms(false));
      });
  };
};

export const copyProgram = (_id = "") => {
  return (dispatch) => {
    return axios
      .get(`/api/coach/programs/${_id}/copy`)
      .then((_res) => {
        dispatch(getProgramLists());
      })
      .catch((err) => {});
  };
};

export const deleteProgramById = (id = "", toggleInfoModel) => {
  return (dispatch) => {
    return axios
      .delete(`/api/coach/programs/${id}`)
      .then(() => {
        dispatch(getProgramLists());
      })
      .catch((err) => {
        toggleInfoModel();
      });
  };
};

export const SET_PROGRAM_DATA = "set_program_data";
export const setProgramData = (response = {}) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PROGRAM_DATA,
      payload: response,
    });
  };
};

export const SET_FORM_DATA = "set_form_data";
export const setFormData = (response = programs.initialValues) => {
  return (dispatch) => {
    dispatch({
      type: SET_FORM_DATA,
      payload: response,
    });
  };
};

export const createNewProgram = (values = {}) => {
  return (dispatch) => {
    return axios
      .post("/api/coach/programs", values)
      .then((res) => {
        history.push("/fitness/programs");
      })
      .catch((err) => {});
  };
};

export const updateProgram = (id = null, values = {}) => {
  return (dispatch) => {
    return axios
      .patch(`/api/coach/programs/${id}`, values)
      .then((res) => {
        history.push("/fitness/programs");
      })
      .catch((err) => {});
  };
};

export const IS_UPLOADING_FILE = "is_uploading_file";
export const toggleFileUploading = (response = false) => {
  return {
    type: IS_UPLOADING_FILE,
    payload: response,
  };
};

export const CLEAN_WHOLE_PROGRAM = "clean_whole_program";
export const cleanAllProgramData = () => {
  return {
    type: CLEAN_WHOLE_PROGRAM,
  };
};

export const saveSessionData = (
  response,
  activeWeek,
  activeDay,
  formikRef = null
) => {
  return (dispatch, getState) => {
    const { setFieldValue, values } = formikRef.current;
    let programItem = getState().programs.programItem;
    let sessionCount = getState().programs.sessionCount;
    setFieldValue(
      `weeks.{${activeWeek + 1}}.{${activeDay + 1}}.session`,
      response
    );

    if (programItem) {
      const oldWeeks = programItem["weeks"]
        ? programItem["weeks"][activeWeek + 1]
        : {};
      programItem["weeks"][activeWeek + 1] = {
        ...oldWeeks,
        [activeDay + 1]: { session: response },
      };
      dispatch(setCurrentProgram(programItem));

      dispatch(setSessionCount(sessionCount + 1));
    }
  };
};

export const SET_SESSION_COUNT = "set_session_count";
export const setSessionCount = (values = 0) => {
  return {
    type: SET_SESSION_COUNT,
    payload: values,
  };
};

export const createNewSession = (
  values = {},
  activeWeek,
  activeDay,
  formikRef = null,
  isIndependentSession = false
) => {
  return (dispatch, getState) => {
    if (isIndependentSession) {
      values.independent_session = true;
    }
    return axios
      .post("/api/coach/sessions", values)
      .then((res) => {
        if (isIndependentSession) {
          history.push("/fitness/sessions");
        } else {
          dispatch(
            saveSessionData(
              res.data.data["_id"],
              activeWeek,
              activeDay,
              formikRef
            )
          );
        }
      })
      .catch((err) => {});
  };
};

export const updateSession = (
  values = {},
  id = "",
  isIndependentSession = false
) => {
  return (dispatch, getState) => {
    if (isIndependentSession) {
      values.independent_session = true;
    }
    return axios
      .patch(`/api/coach/sessions/${id}`, values)
      .then((res) => {
        if (isIndependentSession) {
          history.push("/fitness/sessions");
        } else {
          let sessionCount = getState().programs.sessionCount;
          dispatch(setSessionCount(sessionCount + 1));
        }
      })
      .catch((err) => {});
  };
};

function countObjectProperties(obj) {
  return Object.keys(obj).length;
}

export const getCurrentProgram = (id = null, formikRef = null) => {
  return (dispatch, getState) => {
    dispatch(setLoadingProgramms(true));
    return axios
      .get(`/api/coach/programs/${id}`)
      .then((res) => {
        const response = res.data.data;
        dispatch(getInitialProgram({}, countObjectProperties(response.weeks)));
        dispatch(setCurrentProgram(response));

        if (formikRef) {
          const { setFieldValue } = formikRef.current;
          dispatch(getSessionList(response.weeks, formikRef));
          setFieldValue("weeks", programs.reverseObject(response.weeks));
        } else {
          // const answers = _.map(response.assignment, "answers");
          // const assignment = (answers || []).map((assign) => {
          //   return _.map(assign, "value");
          // });

          const data = _.pick(response, [
            "name",
            "description",
            "status",
            "free_program",
            "e_coach_program",
            "thumbnail",
            "user_id",
            "_id",
            "program_weeks",
          ]);
          const primary_muscle = _.map(response.primary_muscle, (n) => {
            return muscle.find((e) => e.name === n);
          });

          data.primary_muscle = primary_muscle;
          data.next_program_id = response["next_program_id"];
          data.start_date = dayjs(response.start_date);
          data.end_date = dayjs(response.end_date);
          dispatch(
            setFormData({
              ...getState().programs.formData,
              ...data,
            })
          );
        }
        dispatch(setLoadingProgramms(false));
      })
      .catch((err) => {
        dispatch(setLoadingProgramms(false));
      });
  };
};

export const addExercise = (
  response = [],
  groupIndex,
  setFieldValue,
  existingExercises
) => {
  return (dispatch, getState) => {
    let activeDay = getState().programs.activeDay;
    const activeWeek = getState().programs.activeWeek;
    const whereTo = `week.${activeWeek}.[${activeDay}].exercise_groups[${groupIndex}].exercises`;
    setFieldValue(whereTo, [...existingExercises, ...response]);
  };
};

export const getSessionList = (response = {}, isDetail = null) => {
  return (dispatch) => {
    if (isDetail) {
      for (let weekItem in response) {
        const weekData = response[weekItem];
        for (let day in weekData) {
          const dayData = weekData[day];
          for (let session in dayData) {
            const week = Number(weekItem) - 1;
            const daydate = Number(day) - 1;
            dispatch(
              getSessionById(
                dayData.session,
                { index: week, day: daydate },
                isDetail
              )
            );
          }
        }
      }
    }
  };
};

export const GET_INITIAL_PRGRAM = "get_initial_program";
export const getInitialProgram = (data = {}, total = null) => {
  return (dispatch, getState) => {
    const totalWeek = total ? total : getState().programs.totalWeek;
    let list = [];
    for (let i = 0; i <= 6; i++) {
      list.push({
        name: "",
        thumbnail: "",
        calories: "",
        duration: "",
        equipments: [],
        isCompleted: false,
        exercise_groups: [{ muscle: 1, name: "", exercises: [] }],
      });
    }
    const weeks = {};
    for (let i = 0; i <= totalWeek - 1; i++) {
      weeks[i] = list;
    }
    dispatch({
      type: GET_INITIAL_PRGRAM,
      payload: { week: { ...weeks, ...data } },
    });
  };
};

export const getSessionById = (id = "", elements = null, isDetail = null) => {
  return (dispatch, getState) => {
    const { index, day } = elements;
    const { setFieldValue, values } = isDetail.current;
    if (isDetail) {
      const value = day;
      axios
        .get(`/api/coach/sessions/${id}`)
        .then((response) => {
          const whereTo = `week.${index}.[${value}]`;
          const data = response.data.data;

          const equipments = _.map(data.equipments, (n) => {
            return programs.equipments.find((e) => e.name == n);
          });
          setFieldValue(`${whereTo}.name`, data.name);
          setFieldValue(`${whereTo}.calories`, data.calories);
          setFieldValue(`${whereTo}.thumbnail`, data.thumbnail);
          setFieldValue(`${whereTo}.duration`, data.duration);
          setFieldValue(`${whereTo}.equipments`, equipments);
          setFieldValue(`${whereTo}.user_id`, data.user_id);
          setFieldValue(`${whereTo}.status`, data.status);
          setFieldValue(`${whereTo}.exercise_groups`, data.exercise_groups);
          setFieldValue(`${whereTo}.isCompleted`, data.isCompleted);
          data.exercise_groups.forEach((ele, index) => {
            setFieldValue(
              `${whereTo}.exercise_groups[${index}].muscle`,
              (ele.exercises[0]?.sets || []).length
            );
            ele.exercises.forEach((a, index1) => {
              const refineSets = replaceArrayObjValues(a.sets, 0, "");
              setFieldValue(
                `${whereTo}.exercise_groups[${index}].exercises[${index1}].sets`,
                refineSets
              );
            });
          });
        })
        .catch((err) => {});
    }
  };
};

export const SET_CURRENT_COPIED_ITEM = "set_current_copied_item";
export const copySessionData = (formik = {}) => {
  return (dispatch, getState) => {
    const {
      current: { values },
    } = formik;
    let index = getState().programs.activeDay;
    let activeWeek = getState().programs.activeWeek;
    dispatch({
      type: SET_CURRENT_COPIED_ITEM,
      payload: values.week[activeWeek][index],
    });
  };
};

export const deleteSession = (formik = {}, toggleDeleteModel) => {
  return (dispatch, getState) => {
    const {
      current: { setFieldValue },
    } = formik;
    const index = getState().programs.activeDay;
    const activeWeek = getState().programs.activeWeek;
    const programItem = getState().programs.programItem;
    const initialProgramValue = getState().programs.initialProgramValue;
    const sessionId = programItem.weeks[activeWeek + 1][index + 1].session;

    axios
      .delete(`/api/coach/sessions/${sessionId}`)
      .then(() => {
        setFieldValue(
          `week.${activeWeek}.${index}`,
          initialProgramValue.week["0"]["0"]
        );
        delete programItem.weeks[activeWeek + 1][index + 1];
        toggleDeleteModel();
      })
      .catch((err) => {
        toggleDeleteModel();
      });
  };
};

export const pasteSessionData = (formik = {}) => {
  return (dispatch, getState) => {
    const {
      current: { setFieldValue },
    } = formik;
    let copiedSession = getState().programs.copiedSession;
    let index = getState().programs.activeDay;
    let activeWeek = getState().programs.activeWeek;
    const whereTo = `week.${activeWeek}.[${index}]`;
    setFieldValue(whereTo, _.cloneDeep(copiedSession));
    dispatch({
      type: SET_CURRENT_COPIED_ITEM,
      payload: null,
    });
  };
};

export const removeExercise = (element, setFieldValue) => {
  return (dispatch, getState) => {
    const { exercisesList, exerciseIndex, index } = element;
    const activeDay = getState().programs.activeDay;
    const activeWeek = getState().programs.activeWeek;

    exercisesList.splice(index, 1);
    const whereTo = `week.${activeWeek}.[${activeDay}].exercise_groups[${exerciseIndex}].exercises`;
    setFieldValue(whereTo, [...exercisesList]);
  };
};

export const validateSession = (
  values,
  activeWeek = null,
  activeDay = null,
  formikRef = null,
  weeks = null,
  isIndependentSession = false
) => {
  return (dispatch) => {
    let activeElement = null;
    if (!weeks) {
      // Single session save
      weeks = values.weeks ? programs.convertKeys(values.weeks) : {};
      activeElement = values["week"]
        ? values["week"][activeWeek][activeDay]
        : values;
    } else {
      // Multiple session save
      activeElement = values;
      weeks = weeks ? programs.convertKeys(weeks) : {};
    }
    let data = _.pick(activeElement, programs.sessionFields);

    data.equipments = _.map(data.equipments, "name");

    data.calories = data.calories ? data.calories : 0;
    data.duration = data.duration ? data.duration : 0;
    data.user_id = formikRef.current?.values.user_id;
    data.status = formikRef.current?.values.status;

    data.thumbnail = data.thumbnail ? data.thumbnail : THUMBNAIL_PLACEHOLDER;
    let exercise_groups = data.exercise_groups;

    (exercise_groups || []).forEach((exec, index) => {
      const blankArray = createArrayOfElements(exec.muscle);
      const exercises = exercise_groups[index].exercises;

      (exercises || []).forEach((ele, index1) => {
        let refineSets = ele.sets;
        refineSets = replaceArrayObjValues(
          data.exercise_groups[index].exercises[index1].sets,
          "",
          0,
          ["weight", "reps"]
        );
        data.exercise_groups[index].exercises[index1].sets = [
          ...createOrOverrideElement(blankArray, refineSets),
        ];
      });
    });

    if (weeks[activeWeek + 1]) {
      if (weeks[activeWeek + 1][activeDay + 1]) {
        const program = weeks ? weeks[activeWeek + 1][activeDay + 1] : {};
        dispatch(updateSession(data, program.session, isIndependentSession));
      } else {
        dispatch(
          createNewSession(
            data,
            activeWeek,
            activeDay,
            formikRef,
            isIndependentSession
          )
        );
      }
    } else {
      dispatch(
        createNewSession(
          data,
          activeWeek,
          activeDay,
          formikRef,
          isIndependentSession
        )
      );
    }
  };
};
