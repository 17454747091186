import Field from "components/Field";

const RecipeDescription = ({ isRecipeCreatedByAdmin }) => {
  return (
    <Field
      type="list"
      disabledInput={!isRecipeCreatedByAdmin}
      name={`steps`}
      size="small"
      mb={0}
    />
  );
};

export default RecipeDescription;
