import { Box, styled } from "@mui/material";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const EditorStyled = styled(Box)(({ theme }) => ({
  ".editor-class": {
    padding: "1rem",
    border: "1px solid #757E97",
    minHeight: "150px",
    borderRadius: "8px",
    ".public-DraftStyleDefault-block": {
      margin: 0,
    },
    ".public-DraftEditorPlaceholder-inner": {
      fontSize: "14px",
    },
  },
  ".toolbar-class": {
    borderRadius: "8px",
    border: "1px solid #757E97",
    ".hide": {
      display: "none",
    },
    "[title='Indice']": {
      display: "none",
    },
  },
}));
const WysiwygEditor = ({
  savedEditorValue,
  editorValue,
  setEditorValue,
  readOnly = false,
}) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  useEffect(() => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorValue(html);
  }, [editorState]);
  useEffect(() => {
    const html = savedEditorValue || "<p></p>";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState1 = EditorState.createWithContent(contentState);
      setEditorState(editorState1);
    }
  }, [savedEditorValue]);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  return (
    <EditorStyled>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbar-class"
        editorClassName="editor-class"
        readOnly={readOnly}
        onEditorStateChange={onEditorStateChange}
        placeholder="Entrer la description..."
        toolbar={{
          inline: {
            monospace: { className: "hide" },
            superscript: { className: "hide" },
            Indice: { className: "hide" },
          },
          embedded: {
            className: "hide",
          },
          remove: { className: "hide" },
          history: { className: "hide" },
        }}
        localization={{
          locale: "fr",
        }}
      />
    </EditorStyled>
  );
};
export default WysiwygEditor;
