import AddIcon from "@mui/icons-material/Add";
import { Box, Button, IconButton, Stack } from "@mui/material";
import Field from "../index";

const labels = {
  recipe_ingredients: "Ingredient",
  steps: "Étape",
};

const ListInput = (props) => {
  const { form, push, remove, disabledInput } = props;
  return (
    <Box gap={3} display="grid">
      {(form.values[props.name] || []).map((ele, index) => {
        return (
          <Stack direction="row" alignItems="center" gap={2} key={index}>
            <Stack width="100%">
              <Field
                type="text"
                label={`${labels[props.name]} ${index + 1}`}
                name={`${props.name}.${index}`}
                size="small"
                disabled={disabledInput}
                mb={0}
              />
            </Stack>
            {!disabledInput && (
              <IconButton
                color="inherit"
                size="small"
                onClick={() => remove(index)}
              >
                <Box
                  component="img"
                  src="/assets/logo/trash_icon.svg"
                  alt="Exercise delete"
                ></Box>
              </IconButton>
            )}
          </Stack>
        );
      })}
      <Stack direction="row" justifyContent="center">
        <Button
          variant="text"
          disabled={disabledInput}
          onClick={() => push("", "")}
          startIcon={<AddIcon />}
        >
          {props.name === "steps"
            ? "Ajouter une étape"
            : "Ajouter un ingrédient"}
        </Button>
      </Stack>
    </Box>
  );
};

export default ListInput;
