import { Button, Container, Stack } from "@mui/material";
import DetailProgram from "components/EditProgram/DetailProgram";
import Loader from "components/Loader";
import TitleHeader from "components/TitleHeader";
import history from "components/history";
import { addDays } from "date-fns";
import dayjs from "dayjs";
import { Formik } from "formik";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setHeaderDetails } from "store/actions/header";
import {
  getCurrentProgram,
  getInitialProgram,
  getProgramLists,
  setFormData,
  setProgramData,
  setTotalWeek,
} from "store/actions/program";
import { setPrompt } from "store/actions/prompt";
import { ATTENTION_MESSAGE } from "utils/constants";
import { programs } from "utils/programs.utils";

const EditProgramPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const formRef = useRef(null);
  const navigate = useNavigate();

  const formData = useSelector((state) => state.programs.formData);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [editorValue, setEditorValue] = useState("<p></p>");
  const [savedEditorValue, setSavedEditorValue] = useState("<p></p>");
  useEffect(() => {
    if (formData) {
      setSavedEditorValue(formData.description);
    }
  }, [formData]);
  const isLoadingProgramms = useSelector(
    (state) => state.programs.isLoadingProgramms
  );

  useEffect(() => {
    dispatch(getProgramLists());
    if (id && _.isEmpty(location.state)) {
      dispatch(getCurrentProgram(id));
    }
    const navDetail = {
      isBreadcrumns: true,
      title: "",
      breadcrumsList: [
        {
          title: "Programmes d’entraînement",
          link: "/fitness/programs",
          command: () => {
            dispatch(
              setPrompt({
                isOpen: true,
                message: ATTENTION_MESSAGE,
                primaryBtnHandler: () => {
                  navigate("/fitness/programs");
                },
                btnAlign: "right",
                primaryBtnColor: "error",
                secondaryBtnText: "Annuler",
              })
            );
          },
        },
        {
          title: "Étape 1",
          link: "",
        },
      ],
    };
    dispatch(setHeaderDetails(navDetail));
  }, []);

  const handleFormSubmit = () => {
    formRef.current?.handleSubmit();
  };

  return (
    <>
      <Container
        maxWidth="xxl"
        className="header-box"
        sx={{ padding: "0 !important", marginBottom: "20px" }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <TitleHeader title="Créer un nouveau programme" />
          <Button
            type="submit"
            color="success"
            variant="contained"
            onClick={() => handleFormSubmit()}
            disabled={formRef.current?.isSubmitting}
          >
            Continuer
          </Button>
        </Stack>
      </Container>
      <Container maxWidth="xxl">
        <Loader isLoading={isLoadingProgramms} />
        <div style={{ display: isLoadingProgramms ? "none" : "" }}>
          <Formik
            innerRef={formRef}
            initialValues={{ ...formData }}
            enableReinitialize={true}
            onSubmit={(values) => {
              let oldValues = {
                ...values,
                description: editorValue,
                end_date: dayjs(
                  addDays(
                    new Date(values.start_date),
                    values?.program_weeks * 7
                  )
                ),
              };
              let data = _.pick(oldValues, programs.programsFields);
              data.primary_muscle = _.map(data.primary_muscle, "name");
              setIsFormDirty(false);
              dispatch(getInitialProgram({}, values.program_weeks));
              dispatch(setTotalWeek(values.program_weeks));
              dispatch(setFormData({ ...values, description: editorValue }));
              dispatch(setProgramData(data));
              setTimeout(() => {
                history.push(`/fitness/programs/final${id ? "/" + id : ""}`);
              }, 500);
            }}
          >
            {(props) => {
              const { handleSubmit, dirty } = props;
              setIsFormDirty(dirty);
              return (
                <form onSubmit={handleSubmit}>
                  <DetailProgram
                    editorValue={editorValue}
                    setEditorValue={setEditorValue}
                    savedEditorValue={savedEditorValue}
                  />
                </form>
              );
            }}
          </Formik>
        </div>
      </Container>
    </>
  );
};

export default EditProgramPage;
