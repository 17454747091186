import {
  CLEAN_WHOLE_NUTRITION,
  GET_INITIAL_NUTRITION,
  GET_NUTRITIONS_DATA,
  LOADING_NUTRITIONS_DATA,
  PAGINATE_NUTRITION_DATA,
  SEARCH_NUTITIONS_DATA,
  SET_ACTIVE_DAY,
  SET_ACTIVE_NUTRITION_WEEK,
  SET_CURRENT_COPIED_ITEM,
  SET_CURRENT_NUTRITION,
  SET_NUTRITION_DATA,
  SET_TOTAL_WEEK_NUTRITION,
} from "store/actions/nutritions";

export default function nutritions(state = null, action) {
  if (state == null) {
    return {
      isLoadingNutritins: false,
      nutritions: null,
      searchedData: null,
      currentPage: 1,
      nutritionData: {
        name: "",
        description: "",
        thumbnail: null,
        status: "Actif",
        start_date: "",
        end_date: "",
        user_id: "",
        program_weeks: 1,
      },
      nutritionItem: {
        weeks: { 1: {} },
      },
      activeDay: 0,
      activeWeek: 0,
      totalWeek: 1,
      initialNutritionValue: {},
      copiedSession: null,
    };
  }
  switch (action.type) {
    case CLEAN_WHOLE_NUTRITION:
      return {
        ...state,
        nutritionData: {
          name: "",
          description: "",
          thumbnail: null,
          status: "Actif",
          end_date: "",
          start_date: "",
          user_id: "",
          program_weeks: 1,
        },
        nutritionItem: {
          weeks: { 1: {} },
        },
        activeDay: 0,
        activeWeek: 0,
        totalWeek: 1,
        copiedSession: null,
      };
    case SET_CURRENT_NUTRITION:
      return {
        ...state,
        nutritionItem: action.payload,
      };
    case SET_CURRENT_COPIED_ITEM:
      return {
        ...state,
        copiedSession: action.payload,
      };
    case SET_TOTAL_WEEK_NUTRITION:
      return {
        ...state,
        totalWeek: action.payload ? action.payload : state.totalWeek + 1,
      };
    case SET_ACTIVE_NUTRITION_WEEK:
      return {
        ...state,
        activeWeek: action.payload,
      };
    case SET_ACTIVE_DAY:
      return {
        ...state,
        activeDay: action.payload,
      };
    case GET_INITIAL_NUTRITION:
      return {
        ...state,
        initialNutritionValue: action.payload,
      };
    case SET_NUTRITION_DATA:
      return {
        ...state,
        nutritionData: action.payload,
      };
    case PAGINATE_NUTRITION_DATA:
      return {
        ...state,
        currentPage: action.payload,
      };
    case LOADING_NUTRITIONS_DATA:
      return {
        ...state,
        isLoadingNutritins: action.payload,
      };
    case SEARCH_NUTITIONS_DATA:
      return {
        ...state,
        currentPage: 1,
        nutritions: action.payload,
      };
    case GET_NUTRITIONS_DATA:
      return {
        ...state,
        nutritions: action.payload,
        searchedData: action.payload,
      };
    default:
      return state;
  }
}
