import Field from "components/Field";

const IngrediantsContent = ({ isRecipeCreatedByAdmin }) => {
  return (
    <Field
      type="list"
      label={`Ingredient 1`}
      placeholder="100 g de poulet cru"
      name={`recipe_ingredients`}
      disabledInput={!isRecipeCreatedByAdmin}
      size="small"
      isExpandable={true}
      mb={0}
    />
  );
};

export default IngrediantsContent;
