import axios from "axios";
import history from "components/history";
import _ from "lodash";
import { filterRecords } from "utils/common";
import { THUMBNAIL_PLACEHOLDER_GRAY } from "utils/constants";

export const LOADING_NUTRITIONS_DATA = "loading_nutritions_data";
export const loadingNutritions = (response = false) => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_NUTRITIONS_DATA,
      payload: response,
    });
  };
};

export const SET_CURRENT_NUTRITION = "set_current_nutrition";
export const setCurrentNutrition = (values = { weeks: { 1: {} } }) => {
  return {
    type: SET_CURRENT_NUTRITION,
    payload: values,
  };
};

export const GET_INITIAL_NUTRITION = "get_initial_nutrition";
export const getInitialNutrition = (total = null) => {
  return (dispatch, getState) => {
    const totalWeek = total ? total : getState().nutritions.totalWeek;
    let list = [];
    for (let i = 0; i <= 6; i++) {
      list.push({
        desciption: "",
        thumbnail: "",
        meals: [{ name: "", recipes: [] }],
      });
    }
    const weeks = {};
    for (let i = 0; i <= totalWeek - 1; i++) {
      weeks[i] = list;
    }
    dispatch({
      type: GET_INITIAL_NUTRITION,
      payload: { week: { ...weeks } },
    });
  };
};

export const PAGINATE_NUTRITION_DATA = "paginate_nutrition_data";
export const changePagination = (response = 1) => {
  return (dispatch, getState) => {
    dispatch({
      type: PAGINATE_NUTRITION_DATA,
      payload: response,
    });
  };
};

export const SET_NUTRITION_DATA = "set_nutrition_data";
export const setNutritionData = (response = {}) => {
  return (dispatch) => {
    dispatch({
      type: SET_NUTRITION_DATA,
      payload: response,
    });
  };
};

export const SEARCH_NUTITIONS_DATA = "search_nutitions_data";
export const searchNutritions = (search = "", filters = []) => {
  return (dispatch, getState) => {
    let filteredItem = getState().nutritions.searchedData || [];

    filteredItem = filterRecords(filteredItem, search, filters, "status");
    dispatch({
      type: SEARCH_NUTITIONS_DATA,
      payload: filteredItem,
    });
  };
};

export const GET_NUTRITIONS_DATA = "get_nutritions_data";
export const getNutritions = () => {
  return (dispatch, getState) => {
    dispatch(loadingNutritions(true));
    axios
      .get("/api/coach/nutrition_programs")
      .then((res) => {
        dispatch(loadingNutritions(false));
        dispatch({
          type: GET_NUTRITIONS_DATA,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch(loadingNutritions(false));
      });
  };
};

export const deleteNutrition = (id) => {
  return (dispatch, getState) => {
    return axios
      .delete(`/api/coach/nutrition_programs/${id}`)
      .then((res) => {
        dispatch(getNutritions());
      })
      .catch((err) => {});
  };
};

export const SET_ACTIVE_NUTRITION_WEEK = "set_active_nutrition_week";
export const setActiveWeekNutrition = (response) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ACTIVE_NUTRITION_WEEK,
      payload: response,
    });
  };
};

export const SET_TOTAL_WEEK_NUTRITION = "set_total_week_nutrition";
export const setTotalWeekNutrition = (response) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_TOTAL_WEEK_NUTRITION,
      payload: response,
    });
  };
};

export const SET_ACTIVE_DAY = "set_active_day";
export const setActiveDay = (response = 0) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ACTIVE_DAY,
      payload: response,
    });
  };
};

export const duplicateThisWeek = (formikRef, active = 1) => {
  return (dispatch, getState) => {
    const {
      values: { week },
      setFieldValue,
    } = formikRef.current;
    const totalWeek = getState().nutritions.totalWeek;
    setFieldValue(`week.${totalWeek}`, [...week[active]]);
    dispatch(setActiveWeekNutrition(totalWeek));
    dispatch({ type: SET_TOTAL_WEEK_NUTRITION });
  };
};

export const addWeek = (formikRef) => {
  return (dispatch, getState) => {
    const { setFieldValue } = formikRef.current;
    const totalWeek = getState().nutritions.totalWeek;
    const initialWeekValues =
      getState().nutritions.initialNutritionValue.week["0"];
    setFieldValue(`week.${totalWeek}`, initialWeekValues);
    dispatch(setActiveWeekNutrition(totalWeek));
    dispatch({ type: SET_TOTAL_WEEK_NUTRITION });
  };
};

export const removeWeek = (formikRef, active = 1) => {
  return (dispatch, getState) => {
    const {
      values: { week, weeks },
      setFieldValue,
    } = formikRef.current;
    const totalWeek = getState().nutritions.totalWeek;
    const nutritionItem = getState().nutritions.nutritionItem;
    if (Object.keys(week).length > 1) {
      delete nutritionItem.weeks[`${active + 1}`];
      delete week[`${active}`];
      if (weeks) delete weeks[`{${active + 1}}`];
      Object.keys(week).forEach((item) => {
        if (item > active) {
          week[item - 1] = week[item];
          delete week[item];
        }
      });
      Object.keys(nutritionItem.weeks).forEach((item) => {
        if (item > active + 1) {
          nutritionItem.weeks[item - 1] = nutritionItem.weeks[item];
          delete nutritionItem.weeks[item];
        }
      });
      setFieldValue(`week`, week);
      setFieldValue(`weeks`, weeks);
      dispatch(setActiveWeekNutrition(active - 1 < 0 ? 0 : active - 1));
      dispatch({ type: SET_TOTAL_WEEK_NUTRITION, payload: totalWeek - 1 });
      dispatch({ type: SET_CURRENT_NUTRITION, payload: nutritionItem });
    }
  };
};

export const pasteSessionData = (formik = {}) => {
  return (dispatch, getState) => {
    const {
      current: { setFieldValue },
    } = formik;
    let copiedSession = getState().nutritions.copiedSession;
    let index = getState().nutritions.activeDay;
    let activeWeek = getState().nutritions.activeWeek;
    const whereTo = `week.${activeWeek}.[${index}]`;

    setFieldValue(whereTo, copiedSession);
    dispatch({
      type: SET_CURRENT_COPIED_ITEM,
      payload: null,
    });
  };
};

export const SET_CURRENT_COPIED_ITEM = "set_current_copied_item";
export const copySessionData = (formik = {}) => {
  return (dispatch, getState) => {
    const {
      current: { values },
    } = formik;
    let index = getState().nutritions.activeDay;
    let activeWeek = getState().nutritions.activeWeek;
    dispatch({
      type: SET_CURRENT_COPIED_ITEM,
      payload: values.week[activeWeek][index],
    });
  };
};

export const addNutrition = (
  response = {},
  groupIndex,
  setFieldValue,
  existingRecipes
) => {
  return (dispatch, getState) => {
    const activeWeek = getState().nutritions.activeWeek;
    let activeDay = getState().nutritions.activeDay;
    const whereTo = `week.${activeWeek}.[${activeDay}].meals[${groupIndex}].recipes`;
    setFieldValue(whereTo, [...existingRecipes, ...response]);
  };
};

function startKeyFromOne(obj) {
  const newObj = {};
  let index = 1;

  for (const key in obj) {
    newObj[index.toString()] = obj[key];
    index++;
  }

  return newObj;
}

export const returnWeeksLists = (values = {}, nutritionId = null) => {
  return (dispatch, getState) => {
    let data = {};
    let nutritionData = getState().nutritions.nutritionData;
    const weeks = startKeyFromOne(values.week);
    for (let w in weeks) {
      for (let i = 1; i <= weeks[w].length; i++) {
        const activeDay = weeks[w][i - 1];
        const meals = activeDay.meals;
        const hasNonEmptyRecipe = _.some(
          meals,
          (obj) => obj.recipes.length > 0
        );

        if (hasNonEmptyRecipe) {
          data[w] = {
            ...data[w],
            [i]: { menu: activeDay },
          };
        }
      }
    }
    nutritionData.weeks = data;
    nutritionData.status = values.status;
    nutritionData.user_id = values.user_id;
    nutritionData.thumbnail = nutritionData.thumbnail
      ? nutritionData.thumbnail
      : THUMBNAIL_PLACEHOLDER_GRAY;
    dispatch(createNewNutrition(nutritionData, nutritionId));
  };
};

export const createNewNutrition = (values = {}, nutritionId) => {
  return (dispatch, getState) => {
    axios({
      url: `/api/coach/nutrition_programs${
        nutritionId ? `/${nutritionId}` : ""
      }`,
      method: nutritionId ? "PATCH" : "POST",
      data: values,
    })
      .then(() => {
        history.push("/nutrition/programs");
        dispatch(getNutritions());
      })
      .catch(() => {});
  };
};

export const cloneNutritionProgram = (id) => {
  return (dispatch, getState) => {
    axios
      .get(`/api/coach/nutrition_programs/${id}/copy`)
      .then(() => {
        dispatch(getNutritions());
      })
      .catch(() => {});
  };
};

export const getSessionsLists = (response, formikRef) => {
  return () => {
    const { setFieldValue } = formikRef.current;
    for (let weekItem in response) {
      const weekData = response[weekItem];
      for (let day in weekData) {
        const dayData = weekData[day];
        for (let session in dayData) {
          const week = Number(weekItem) - 1;
          const daydate = Number(day) - 1;
          const whereTo = `week.${week}.[${daydate}]`;
          const currentMenu = response[weekItem][day].menu;
          setFieldValue(whereTo + ".thumbnail", currentMenu.thumbnail);
          setFieldValue(whereTo + ".desciption", currentMenu.desciption);
          setFieldValue(whereTo + ".meals", currentMenu.meals);
        }
      }
    }
  };
};

function countObjectProperties(obj) {
  return Object.keys(obj).length;
}

export const CLEAN_WHOLE_NUTRITION = "clean_whole_nutrition";
export const cleanAllNutritionData = () => {
  return {
    type: CLEAN_WHOLE_NUTRITION,
  };
};

export const getCurrentNutrition = (id = null, formikRef) => {
  return (dispatch, getState) => {
    dispatch(loadingNutritions(true));
    axios
      .get(`/api/coach/nutrition_programs/${id}`)
      .then((res) => {
        dispatch(loadingNutritions(false));
        const response = res.data.data;
        dispatch(getInitialNutrition(countObjectProperties(response.weeks)));
        dispatch(setCurrentNutrition(response));

        if (formikRef) {
          dispatch(getSessionsLists(response.weeks, formikRef));
        } else {
          let data = response;
          data.name = response.name;
          data.description = response.description;
          data.thumbnail = response.thumbnail;
          data.start_date = response.start_date;
          data.end_date = response.end_date;
          data.weeks = response.weeks;

          dispatch(
            setNutritionData({
              ...getState().nutritions.nutritionData,
              ...data,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(loadingNutritions(false));
      });
  };
};
